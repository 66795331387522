import { StoreAction } from "../../@core/constants/action";

const initalState = {
  unitList: [],
};

export default function (state = initalState, action) {
  switch (action.type) {
    case StoreAction.GET_UNIT: {
      return {
        ...state,
        unitList: action.payload,
      };
    }
    default:
      return state;
  }
}
