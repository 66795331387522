import { StoreAction } from "../../@core/constants/action";

const initialState = {
  notificationList: [],
  last_notification_id: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_ALL_NOTIFICATION_DATA:
      return {
        ...state,
        notificationList: action.payload.notification,
        last_notification_id: action.payload.n_last_id,
      };
    case StoreAction.READ_ALL_NOTIFICATION:
      return { ...initialState };
    default:
      return state;
  }
}
