import { StoreAction } from "../../@core/constants/action";

const initalState = {
  departmentList: [],
  userDepartmentList: [], //store user child departments with tree
  allTreeDepartmentList: [], //store all department list with tree
};

export default function (state = initalState, action) {
  switch (action.type) {
    case StoreAction.GET_DEPARTMENT: {
      return {
        ...state,
        departmentList: action.payload,
      };
    }
    case StoreAction.GET_USER_DEPARTMENT: {
      return {
        ...state,
        userDepartmentList: action.payload,
      };
    }
    case StoreAction.GET_ALL_TREE_DEPARTMENT: {
      return {
        ...state,
        allTreeDepartmentList: action.payload,
      };
    }
    default:
      return state;
  }
}
