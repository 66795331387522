import { StoreAction } from "../../@core/constants/action";

const initialState = {
  myToDoList: [],
  pendingToDoList: [],
  recursiveToDoList: [],
  allTodoList: [],
  dashboardTodoList: [],
  totalAllTodoCounter: 0,
  totalRecursiveTodoCounter: 0,
  customTodoView: 0, // 0-today todo 1-all todo
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_TODO_LIST:
      return {
        ...state,
        myToDoList: action?.payload,
      };
    case StoreAction.GET_PENDING_TODO_LIST:
      return {
        ...state,
        pendingToDoList: action?.payload,
      };
    case StoreAction.GET_RECURSIVE_TODO_LIST:
      return {
        ...state,
        recursiveToDoList: action?.payload,
      };
    case StoreAction.GET_ALL_TODO_LIST:
      return {
        ...state,
        allTodoList: action?.payload,
      };

    case StoreAction.GET_DASHBOARD_TODO_LIST:
      return {
        ...state,
        dashboardTodoList: action?.payload,
      };
    case StoreAction.GET_TOTAL_ALL_TODO_COUNTER:
      return {
        ...state,
        totalAllTodoCounter: action?.payload,
      };
    case StoreAction.GET_TOTAL_RECURSIVE_TODO_COUNTER:
      return {
        ...state,
        totalRecursiveTodoCounter: action?.payload,
      };
    case StoreAction.SET_CUSTOM_TODO_VIEW: {
      return {
        ...state,
        customTodoView: action.payload,
      };
    }

    default:
      return state;
  }
}
