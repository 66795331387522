import { StoreAction } from "../../@core/constants/action";

const initalState = {
  unitList: [],
  leadLabelList: [],
  events_data: [],
  events_dates: [],
  leadStatusList: [],
  leadSourceList: [],
  get_allstaff: [],
  lead_today_data: [],
  task_status: [],
  task_label: [],
  webSetting_data: [],
  deptSetting_data: [],
  today_event_data: [],
  reminder_meeting_data: [],
  webSocket: null, // First Web socket for chat and all other notifications
};

export default function (state = initalState, action) {
  switch (action.type) {
    case StoreAction.SET_WEB_SOCKET:
      return {
        ...state,
        webSocket: action.payload,
      };

    case StoreAction.GET_LEAD_LABEL: {
      return {
        ...state,
        leadLabelList: action.payload,
      };
    }
    case StoreAction.GET_LEAD_STATUS: {
      return {
        ...state,
        leadStatusList: action.payload,
      };
    }
    case StoreAction.GET_TODAY_LEADS: {
      return {
        ...state,
        lead_today_data: action.payload,
      };
    }
    case StoreAction.GET_ALLSTAFF_DATA:
      return {
        ...state,
        get_allstaff: action.payload,
      };
    case StoreAction.GET_LEAD_SOURCE: {
      return {
        ...state,
        leadSourceList: action.payload,
      };
    }
    case StoreAction.GET_TASK_LABEL: {
      return {
        ...state,
        task_label: action.payload,
      };
    }
    case StoreAction.GET_TASK_STATUS: {
      return {
        ...state,
        task_status: action.payload,
      };
    }
    case StoreAction.GET_WEBSETTING_DATA:
      return {
        ...state,
        webSetting_data: action.payload,
      };
    case StoreAction.GET_DEPTSETTING_DATA:
      return {
        ...state,
        deptSetting_data: action.payload,
      };
    case StoreAction.GET_CALENDER_EVENTS: {
      return {
        ...state,
        events_data: action.payload,
        events_data_loading: false,
      };
    }
    case StoreAction.GET_CALENDER_DATES: {
      return {
        ...state,
        events_dates: action.payload,
      };
    }
    case StoreAction.GET_TODAY_EVENTS: {
      return {
        ...state,
        today_event_data: action.payload,
      };
    }
    case StoreAction.GET_REMINDER_MEETING_DATA: {
      return {
        ...state,
        reminder_meeting_data: action.payload,
      };
    }
    case StoreAction.IS_GLOBAL_SEARCH_SUGGESTION_OPEN:
      return {
        ...state,
        is_Global_Search_Suggestions_open: action.payload,
      };
    case StoreAction.IS_GLOBAL_SEARCH_OPEN:
      return {
        ...state,
        is_Global_Search_open: action.payload,
      };
    default:
      return state;
  }
}
