import { StoreAction } from "../../@core/constants/action";

const initialState = {
  all_task: [],
  task_data: [],
  staff_data: [],
  today_task_data: [],
  kanban_task_ids: [],
  tomorrowTaskList: [],
  search_kanban_task: [],
  task_history_data: [],
  is_task_loading: false,
  is_taskData_loading: false,
  is_leadtask_loading: false,
  is_today_task_loading: false,
  taskstatus_btn_loading: false,
  is_task_insert_button_loading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_TASK_DATA:
      return {
        ...state,
        task_data: action?.payload,
        is_task_insert_button_loading: false,
        is_task_loading: false,
      };

    case StoreAction.SET_IS_TODAY_TASKS_LOADING:
      return {
        ...state,
        is_today_task_loading: action.payload,
      };

    case StoreAction.TASKSTATUS_BTN_LOADING:
      return {
        ...state,
        taskstatus_btn_loading: action.payload,
      };
    case StoreAction.GET_TODAY_TASK_DATA:
      return {
        ...state,
        today_task_data: action?.payload,
        is_today_task_loading: false,
      };
    case StoreAction.SET_IS_TASK_LOADING:
      return {
        ...state,
        is_task_loading: action.payload,
      };
    case StoreAction.SET_IS_INSERT_BUTTON_LOADING:
      return {
        ...state,
        is_task_insert_button_loading: action.payload,
      };
    case StoreAction.GET_ALL_KANBAN_TASK_IDS: {
      return {
        ...state,
        kanban_task_ids: action.payload,
        is_task_insert_button_loading: false,
      };
    }
    case StoreAction.GET_ALL_KANBAN_TASK_SEARCH: {
      return {
        ...state,
        search_kanban_task: action.payload,
      };
    }
    case StoreAction.GET_ALL_TASK_DATA: {
      return {
        ...state,
        all_task: action.payload.tasks,
        is_task_insert_button_loading: false,
      };
    }
    case StoreAction.GET_ALL_TOMORROW_TASK: {
      return {
        ...state,
        tomorrowTaskList: action.payload,
      };
    }
    case StoreAction.GET_TASK_HISTORY: {
      return {
        ...state,
        task_history_data: action.payload,
      };
    }
    default:
      return state;
  }
}
