import { StoreAction } from "../../@core/constants";

const initialState = {
  daily_Attendance: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.DAILY_STAFF_ATTENDANCE:
      return {
        ...state,
        daily_Attendance: action.payload,
      };
    default:
      return state;
  }
}
