import { StoreAction } from "../../@core/constants/action";

const initalState = {
  userList: [],
};

export default function (state = initalState, action) {
  switch (action.type) {
    case StoreAction.GET_USER: {
      return {
        ...state,
        userList: action.payload,
      };
    }
    default:
      return state;
  }
}
