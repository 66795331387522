export const ApiRoutes = {
  API_HOSTNAME: process.env.REACT_APP_API_ENDPOINT,
  WHATSAPP_URL: process.env.REACT_APP_WHATSAPP_URL,
  WS_HOSTNAME: process.env.REACT_APP_WEB_SOCKET_URL,
  API_HOSTNAME_WP: process.env.REACT_APP_HOSTNAME_WP,
  API_HOSTNAME_CONNECT_CRM: process.env.REACT_APP_CONNECT_CRM,
  // WS_SECOND_HOSTNAME: process.env.REACT_APP_WEB_SOCKET_SECOND_URL,
  WAPMONKEY_WS_HOST: process.env.REACT_APP_WAP_SOCKET_URL,

  LOGIN: "login",
  LOGOUT: "logout",
  SENDSMS: "sendsms",
  GETTODO: "gettodo",
  GETCOLOR: "getcolor",
  GETNOTES: "getnotes",
  SENDEMAIL: "sendemail",
  CHECKEMAIL: "checkemail",
  DELETELEAD: "deletelead",
  UPDATELEAD: "updatelead",
  IMPORTLEAD: "importlead",
  INSERTLEAD: "insertlead",
  INSERTTASK: "inserttask",
  UPDATETASK: "updatetask",
  DELETETASK: "deletetask",
  UPDATETODO: "updatetodo",
  DELETETODO: "deletetodo",
  INSERTTODO: "inserttodo",
  DELETENOTE: "deletenotes",
  GETHOLIDAYS: "getholiday",
  INSERTCOLOR: "insertcolor",
  DELETECOLOR: "deletecolor",
  INSERTNOTES: "insertnotes",
  UPDATENOTES: "updatenotes",
  GETTEMPLATE: "gettemplate",
  INSERTLEAVE: "insertleave",
  UPDATELEAVE: "updateleave",
  GETCHATLIST: "getchatlist",
  INSERTEVENT: "insertevent",
  DELETEEVENT: "deleteevent",
  UPDATEEVENT: "updateevent",
  GLOBALSEARCH: "globalsearch",
  TESTMAILSEND: "testmailsend",
  GETCHATGROUP: "getchatgroup",
  GETLEAVELIST: "getleavedata",
  GETTODAYLEAD: "gettodaylead",
  INSERTHOLIDAY: "insertholiday",
  UPDATEHOLIDAY: "updateholiday",
  DELETEHOLIDAY: "deleteholiday",
  ADDDEPARTMENT: "adddepartment",
  UPDATEFBTOKEN: "updatefbtoken",
  GETATTRIBUTES: "getattributes",
  GETCUSTOMTODO: "getcustomtodo",
  WAPMONKEYLINK: "linktowapmonkey",
  GETPROFILEDATA: "getprofiledata",
  CHANGEPASSWORD: "changepassword",
  UPDATEREMINDER: "updatereminder",
  GETNOTIFACTION: "getnotifaction",
  GETDEPARTMENTS: "getdepartments",
  GETLEADHISTORY: "getleadhistory",
  INSERTTEMPLATE: "inserttemplate",
  UPDATETEMPLATE: "updatetemplate",
  DELETETEMPLATE: "deletetemplate",
  GETALLTODOLIST: "getalltodolist",
  GETTASKHISTORY: "gettaskhistory",
  REQUESTTOSTAFF: "requesttostaff",
  WAPGETUSERDEVICE: "getuserdevice",
  PENDINGTODOLIST: "getpendingtodo",
  GETTODOREPORT: "customtodoreport",
  WAPDEVICELOGOUT: "apidevicelogout",
  WPCHECKACCOUNT: "checkuseraccount",
  INSERTATTRIBUTE: "insertattribute",
  UPDATEATTRIBUTE: "updateattribute",
  GETLEADFOLLOWUP: "getleadfollowup",
  CHECKLEADMOBILE: "checkleadmobile",
  UPDATELEADLABEL: "updateleadlabel",
  DELETEATTRIBUTE: "deleteattribute",
  GETSTAFFFORCHAT: "getstaffforchat",
  INSERTCHATGROUP: "insertchatgroup",
  UPDATETASKLABELS: "updatetasklabel",
  UPDATETODOLABELS: "updatetodolabel",
  GETREMINDERBYCID: "getreminderbycid",
  GETDASHBOARDTASK: "getdashboardtask",
  UPDATEDEPARTMENT: "updatedepartment",
  GETREMINDERANDMEETING: "getreminder",
  UPDATELEADSTATUS: "updateleadstatus",
  INSERTCUSTOMTODO: "insertcustomtodo",
  UPDATETODOSTATUS: "updatetodostatus",
  DELETECUSTOMTODO: "deletecustomtodo",
  READNOTIFICATION: "readnotification",
  WAPMONKEYREGISTRATION: "registration",
  GETCALENDEREVENT: "getcalenderevents",
  INSERTTODOCOMMENT: "inserttodocomment",
  UPDATETODOCOMMENT: "updatetodocomment",
  DELETETODOCOMMENT: "deletetodocomment",
  GETMEETINGHISTORY: "getmeetinghistory",
  GETUSERLISTBYDEPT: "getuserlistbydept",
  UPDATEPROFILEDATA: "updateprofiledata",
  UPDATENOTIFACTION: "updatenotifaction",
  GETUSERPERMISSION: "getuserpermission",
  INSERTTASKCOMMENT: "inserttaskcomment",
  UPDATELEAVESTATUS: "updateleavestatus",
  GETUSERBYDEPTANDUNITWISE: "getuserlist",
  INSERTLEADASSIGNTO: "insertleadassignto",
  DELETEMULTIPLETASK: "deletemultipletask",
  UPDATETASKASSIGNTO: "updatetaskassignto",
  GETFIRSTLEVELCHILD: "getfirstlevelchild",
  INSERTLEADFOLLOWUP: "insertleadfollowup",
  GETCUSTOMERDETAILS: "getcustomerdetails",
  INSERTSTAFFCHECKIN: "insertstaffcheckin",
  GETAUTOMATIONRULES: "getautomationrules",
  CHECKWAPMONKEYACCOUNT: "checkuseraccount",
  DELETEREMINDERORMEETING: "deletereminder",
  UPDATEREMINDERORMEETING: "updatereminder",
  GETDELAYTASKREPORT: "gettasktdelayreport",
  GETLEADCOUNTERBYCID: "getleadcounterbycid",
  GETCHATGROUPDETAILS: "getchatgroupdetails",
  INSERTREMINDERANDMEETING: "insertreminder",
  UPDATEMEETINGSTATUS: "updatemeetingstatus",
  UPDATESTAFFCHECKOUT: "updatestaffcheckout",
  ADDORGANIZATIONUNIT: "addorganizationunit",
  GETORGANIZATIONUSER: "getorganizationuser",
  GETORGANIZATIONUNIT: "getorganizationunit",
  INSERTRECURSIVETODO: "insertrecursivetodo",
  UPDATERECURSIVETODO: "updaterecursivetodo",
  DELETERECURSIVETODO: "deleterecursivetodo",
  READALLNOTIFICATION: "read-allnotification",
  ADDORGANIZATIONSTAFF: "addorganizationuser",
  GETLABELWISETASKREPORT: "gettasklabelreport",
  GETLABELWISELEADREPORT: "getleadlabelreport",
  UPDATECUSTOMERSTATUS: "updatecustomerstatus",
  DELETELEADATTACHMENT: "deleteleadattachment",
  ASSIGNTOMULTIPLELEAD: "assigntomultiplelead",
  CHANGEFORGOTPASSWORD: "changeforgotpassword",
  INSERTUSERPERMISSION: "insertuserpermission",
  UPDATEUSERPERMISSION: "updateuserpermission",
  DELETETASKATTACHMENT: "deletetaskattachment",
  GETALLCUSTOMTODOLIST: "getallcustomtodolist",
  DELETEPACKAGEREQUEST: "deletepackagerequest",
  UPDATEPACKAGEREQUEST: "updatepackagerequest",
  UPDATEDEPTSETTINGS: "updatedepartmentsetting",
  WAPREMAININGCREDITAPI: "remaining-credit-api",
  WAPMONKEYSENDTESTMESSAGE: "testwpmessagesend",
  UPDATERECURSIVECUSTOMTODO: "updatecustomtodo",
  CHECKATTENDANCEPASSWORD: "checkattendancepwd",
  GETSTATUSWISETASKREPORT: "gettaskstatusreport",
  GETSOURCEWISELEADREPORT: "getleadsourcereport",
  GETSTATUSWISELEADREPORT: "getleadstatusreport",
  GETPACKAGEREQUESTLIST: "getpackagerequestlist",
  SEARCHALLLEADPAGINATE: "searchallleadpaginate",
  SEARCHALLTASKPAGINATE: "searchalltaskpaginate",
  LEADCONVERTTOCUSTOMER: "leadconverttocustomer",
  GETUSERPERMISSIONBYID: "getuserpermissionbyid",
  INSERTLEADINQUIRYFORM: "insertleadinquiryform",
  INSERTAUTOMATIONRULES: "insertautomationrules",
  UPDATEAUTOMATIONRULES: "updateautomationrules",
  DELETEAUTOMATIONRULES: "deleteautomationrules",
  UPDATECUSTOMERDETAILS: "updatecustomerdetails",
  UPDATESTAFFATTENDANCE: "updatestaffattendance",
  GETDATAFORINQUIRYFORM: "getdataforinquiryform",
  GETSTAFFATTENDANCEREPORT: "getattendancereport",
  GETCOMPANYWEBSETTING: "getorganizationunitbyid",
  UPDATEORGANIZATIONUNIT: "updateorganizatonunit",
  GETREMINDERDETAILSBYID: "getreminderdetailbyid",
  UPDATEORGANIZATIONUSER: "updateorganizatonuser",
  WAPMONKEYCHECKREGISTEROTP: "check-register-otp",
  UPDATEATTENDANCEPASSWORD: "updateattendancepwd",
  UPDATECUSTOMTODOLABELS: "updatecustomtodolabel",
  REGISTRATIONVALIDATION: "registrationvalidation",
  GETLEADSTATUSCHARTDATA: "getleadstatuschartdata",
  GETLEADSOURCECHARTDATA: "getleadsourcechartdata",
  UPDATECUSTOMTODOSTATUS: "updatecustomtodostatus",
  INSERTRESCHDULEMEETING: "insertreschdulemeeting",
  DELETEMULTIPLEREMINDER: "deletemultiplereminder",
  GETSTAFFCHECKINOUTDATA: "getstaffcheckinoutdata",
  ALLOCATELEADLABELASSIGN: "allocateleadlabelassign",
  ALLOCATELEADLABELASSIGN: "allocateleadlabelassign",
  CHECKSUBDEPARTMENTEXIST: "checksubdepartmentexist",
  GETALLDEPARTMENTFORTREE: "getalldepartmentfortree",
  GETDEPARTMENTDETAILBYID: "getdepartmentdetailbyid",
  INSERTCUSTOMTODOCOMMENT: "insertcustomtodocomment",
  DELETECUSTOMTODOCOMMENT: "deletecustomtodocomment",
  UPDATECUSTOMTODOCOMMENT: "updatecustomtodocomment",
  GETPRIORITYWISETASKREPORT: "gettasktpriorityreport",
  GETREMINDERWISEASSIGNUSER: "getreminderassignusers",
  UPDATECOMPANYWEBSETTINGS: "updatecompanywebsetting",
  DELETECUSTOMERATTACHMENT: "deletecustomerattachment",
  GETUSERLISTBYUNITANDDEPT: "getuserlistbyunitanddept",
  DELETETEMPLATEATTACHMENT: "deletetemplateattachment",
  GETUSERLISTFORATTENDANCE: "getuserlistforattendance",
  STAFFMONTHLYATTENDANCE: "staffmonthlyattendancereport",
  UPDATESTAFFATTENDANCENOTE: "updatestaffattendancenote",
  REQUESTPACKAGETOMAINADMIN: "requestpackagetomainadmin",
  UPDATEPACKAGESTATUSCHANGE: "updatepackagestatuschange",
  GETDASHBOARDREMINDERANDMEETING: "getreminderdashboard",
  GETLEADLINKSMODULECOUNTER: "getleadlinksmodulecounter",
  GETFOLLOWUPWISELEADREPORT: "getleadfollowupwisererport",
  DELETECUSTOMTODOATTACHMENT: "deletecustomtodoattechment",
  LEADFOLLOWUPWISEUSERREPORT:"getleadfollowupuserwisereport",
  UPDATEORGANIZATIONUNITSTATUS: "updateorganizatonunitstatus",
  GETTHIRDPARTYSWISELEADREPORT: "getleadthirdpartywiseRerport",
  GETCUSTOMERWISEFOLLOWUPREPORT:"getcustomerwisefollowupreport",
  GETREQUESTPACKAGETOMAINADMIN: "getrequestpackagetomainadmin",
  UPDATEORGANIZATIONSTAFFSTATUS: "updateorganizatonuserstatus",
  ATTENDANCEINSERTSTAFFCHECKIN: "attendance-insertstaffcheckin",
  UPDATESTAFFATTENDANCENOTETYPE: "updatestaffattendancenotetype",
  ATTENDANCEUPDATESTAFFCHECKOUT: "attendance-updatestaffcheckout",
  UPDATETHIRDPARTYINTEGRATION: "updatecompanythirdpartyintegration",
  DELETEREQUESTPACKAGETOMAINADMIN: "deleterequestpackagetomainadmin",
  UPDATEREQUESTPACKAGETOMAINADMIN: "updateRequestPackageToMainAdmin",
  GETMONTHORYEARWISELEADSTATUSREPORT: "getleadstatusmonthoryearwisereport",
  ATTENDANCEGETUSERLISTFORATTENDANCE: "attendance-getuserlistforattendance",
};
