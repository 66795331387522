import { StoreAction } from "../../@core/constants/action";

const initialState = {
  email_automationrules: [],
  sms_automationrules: [],
  whatsapp_automationrules: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_EMAIL_AUTOMATIONRULES:
      return {
        ...state,
        email_automationrules: action.payload,
      };
    case StoreAction.GET_SMS_AUTOMATIONRULES:
      return {
        ...state,
        sms_automationrules: action.payload,
      };
    case StoreAction.GET_WHATSAPP_AUTOMATIONRULES:
      return {
        ...state,
        whatsapp_automationrules: action.payload,
      };

    default:
      return state;
  }
}
