import { StoreAction } from "../../@core/constants/action";

const initialState = {
  smsTemplateList: [],
  emailTemplateList: [],
  meetingTemplateList: [],
  whatsAppTemplateList: [],
  reminderTemplateList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplateList: action.payload,
      };
    case StoreAction.GET_SMS_TEMPLATE:
      return {
        ...state,
        smsTemplateList: action.payload,
      };
    case StoreAction.GET_WHATSAPP_TEMPLATE:
      return {
        ...state,
        whatsAppTemplateList: action.payload,
      };
    case StoreAction.GET_REMINDER_TEMPLATES:
      return {
        ...state,
        reminderTemplateList: action.payload,
      };
    case StoreAction.GET_MEETING_TEMPLATES:
      return {
        ...state,
        meetingTemplateList: action.payload,
      };
    default:
      return state;
  }
}
