import { StoreAction } from "../../@core/constants/action";

const initialState = {
  groupMood: 0, // 0 - insert Group, 1 - Update Group
  allChatList: {}, // side-bar chat list
  selectedChat: {}, // Selected Chat
  groupModal: false, // Open Group Insert update modal
  scrollLoading: false, // Scroll Loader show in selected chat
  isSendMessage: false, // Send Message Button disable
  scrollAdject: false, // Scroll adject on evry render
  groupMemberList: [], // Set Group Member
  activeChat: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_ALL_CHAT_LIST:
      return { ...state, allChatList: action.payload };
    case StoreAction.SET_SELECTED_CHAT:
      return { ...state, selectedChat: action.payload };
    case StoreAction.SET_IS_SEND_MESSAGE:
      return { ...state, isSendMessage: action.payload };
    case StoreAction.SET_GROUP_MODAL:
      return { ...state, groupModal: action.payload };
    case StoreAction.SET_GROUP_MOOD:
      return { ...state, groupMood: action.payload };
    case StoreAction.SET_SCROLL_LOADING:
      return { ...state, scrollLoading: action.payload };
    case StoreAction.SET_SCROLL_ADJECT:
      return { ...state, scrollAdject: action.payload };
    case StoreAction.SET_GROUP_MEMBER:
      return { ...state, groupMemberList: action.payload };
    case StoreAction.SET_ACTIVE_CHAT:
      return { ...state, activeChat: action.payload };
    default:
      return state;
  }
}
