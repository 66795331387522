import { StoreAction } from "../../@core/constants/action";

const initialState = {
  leaveList: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_ALL_LEAVE_DATA:
      return {
        ...state,
        leaveList: action.payload,
      };
    default:
      return state;
  }
}
